import React from "react"
import { shape, ProfileType } from "../../types"

const Summary = ({ profile }) => (
  <div className="flex pb-8">
    <div className="w-1/2 pr-4 lg:pr-12 border-r border-line">
      <h5 className="font-header font-semibold text-front text-sm uppercase">
        Company
      </h5>
      {profile.focus_url && (<a
        aria-label="website"
        // className="inline-block text-front opacity-50 hover:opacity-75 h-4 w-4 transition-opacity duration-150"
        href={profile.focus_url}
        rel="noopener"
        target="_blank"
      >
        <h3 className="font-header font-light text-2xl text-front leading-tight">
          {profile.company}
        </h3>
      </a>)}
      {!profile.focus_url && (
        <h3 className="font-header font-light text-2xl text-front leading-tight">
          {profile.company}
        </h3>)}
    </div>
    <div className="w-1/2 pl-4 lg:pl-12">
      <h5 className="font-header font-semibold text-front text-sm uppercase">
        Known for
      </h5>
      <div className="font-header font-light text-2xl text-front leading-tight">
        {profile.focus}
      </div>
    </div>
  </div>
)

Summary.propTypes = {
  profile: shape(ProfileType),
}

export default Summary
